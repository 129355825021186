import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LiteYoutubeComponent } from '../lite-youtube/lite-youtube.component';
import { SharedModule } from '../shared.module';

@NgModule({
  declarations: [
    LiteYoutubeComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [LiteYoutubeComponent]
})
export class LiteYoutubeModule { }
