import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {LoaderComponent} from "./loader.component";
import {NgxLoadingModule} from "ngx-loading";
import { SharedModule } from "src/app/shared.module";


@NgModule({
  declarations: [
    LoaderComponent
  ],
  imports: [

    CommonModule,
    NgxLoadingModule.forRoot({fullScreenBackdrop: true})
  ],
  exports: [
    LoaderComponent
  ]
})
export class LoaderModule {
}
