import {NgModule} from '@angular/core';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {environment} from '../environments/environment';
import {ApolloClientOptions, InMemoryCache} from "@apollo/client/core";
import {onError} from '@apollo/client/link/error';

const error = onError(({graphQLErrors, networkError}) => {
  console.log('networkError');
  console.log(networkError);
  if (graphQLErrors) {

    // if (graphQLErrors[0].extensions
    //   && graphQLErrors[0].extensions.response
    //   && graphQLErrors[0].extensions.response.statusCode === 401) {
    //   window.location.href = '/logout';
    // }


    graphQLErrors.map(({message, locations, path}) => {
        // Swal.fire({
        //   title: 'Error',
        //   html: message,
        //   icon: 'warning',
        //   confirmButtonColor: '#032E58',
        // });
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      },
    );

    // window.location.href = '/logout';
  }

  if (networkError) {
    const e: any = networkError;
    console.log(networkError);

    if (e.status === 401) {
      // this.router.navigate(['/']);
    }

    if (e.status === 400) {
      let str = '';
      e.error.errors.map((x: any) => {
        if (x.message.length > 0) {
          str += x.message;
        }
      });
      // alert(str);
      console.log(str);
    }
  }
});

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  const defaultOptions: any = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
  return {
    link: error.concat(httpLink.create({uri: environment.graphql_url})), // httpLink.create({uri}),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {
}
