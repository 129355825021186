import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {GoogleTagManagerService} from "angular-google-tag-manager";



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {

  constructor(public router: Router,
              private gtmService: GoogleTagManagerService,
              
  ) {
    
  }

  

  ngOnInit(): void {
    
    // push GTM data layer for every visited page
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        // console.log(gtmTag);
        this.gtmService.pushTag(gtmTag);
      }
    });
  }

}
