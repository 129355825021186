import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import { LoginModule } from './shared/login/login.module';
import { LiteYoutubeModule } from './lite-youtube/lite-youtube.module';
import { FooterModule } from './shared/footer/footer.module';
import { RouterModule } from '@angular/router';
import { TrackingComponent } from './tracking/tracking.component';
@NgModule({
  declarations: [
    TrackingComponent,
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LiteYoutubeModule,
    LoginModule,
    FooterModule,
    RouterModule

  ],
  providers: [
    {provide: 'googleTagManagerId', useValue: 'GTM-P355B9G'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
