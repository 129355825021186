import {Injectable} from "@angular/core";
import {Subject} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public isShow = new Subject<boolean>()

  constructor() {
  }

  show() {
    this.isShow.next(true);
  }

  hide() {
    this.isShow.next(false);
  }

}
