import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ApolloModule } from 'apollo-angular';
import { NgxMaskModule } from 'ngx-mask';
import { LoaderModule } from './base/loader/loader.module';
import { GraphQLModule } from './graphql.module';
import { FooterModule } from './shared/footer/footer.module';
import { LoginModule } from './shared/login/login.module';


@NgModule({
  declarations: [],
  imports: [
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    FooterModule,
    ApolloModule,
    HttpClientModule,
    GraphQLModule,
    NgxMaskModule.forRoot(),
    LoaderModule,
    LoginModule
  ],
  exports: [
    RouterModule,
    FooterModule,
    ReactiveFormsModule,
    ApolloModule,
    CommonModule,
    HttpClientModule,
    LoginModule,
    GraphQLModule,
    NgxMaskModule,
    LoaderModule
  ]
})
export class SharedModule { }
