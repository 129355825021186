import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  {
    path: 'politica-de-privacidade-boty',
    loadChildren: () => import('./whatsapp-politic/whatsapp-politic.module').then(m => m.WhatsappPoliticModule),
  },
  { path: 'not-found', loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule), data: {
    social: 8031,
  }, },
  { path: '', loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule), data: {
    social: 8031,
  }, },
  {
    path: 'agradecimento',
    loadChildren: () => import('./agradecimento/agradecimento.module').then(m => m.AgradecimentoModule),
  },
  {
    path: "agradecimento-obg",
    loadChildren: () => import('./agradecimento-obg/agradecimento.module').then(m => m.AgradecimentoObgModule),
  },
  {
    path: "agradecimento-redirect",
    loadChildren: () => import('./agradecimento-redirect/agradecimento.module').then(m => m.AgradecimentoRedirectModule)
  },
  {
    path: "cotacao-pocket",
    loadChildren: () => import('./newlp-pocket/newlp-pocket.module').then(m => m.NewLpPocketModule),
    data: {
      social: 8273
    }
  },
  {
    path: 'ad0101',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8186,
    },
  },
  {
    path: 'ad0102',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8187,
    },
  },
  {
    path: 'ad0103',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8189,
    },
  },
  {
    path: 'ad0104',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8190,
    },
  },
  {
    path: 'ad0105',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8191,
    },
  },
  {
    path: 'ad0106',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8192,
    },
  },
  {
    path: 'ad0107',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8193,
    },
  },
  {
    path: 'ad0108',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8194,
    },
  },
  {
    path: 'ad0109',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8195,
    },
  },
  {
    path: 'ad0110',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8196,
    },
  },
  {
    path: 'ad0111',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8197,
    },
  },
  {
    path: 'ad0112',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8198,
    },
  },
  {
    path: 'ad0113',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8199,
    },
  },
  {
    path: 'ad0114',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8200,
    },
  },
  {
    path: 'ad0115',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8201,
    },
  },
  {
    path: 'ad0116',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8202,
    },
  },
  {
    path: 'ad0117',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8203,
    },
  },
  {
    path: 'ad0118',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8204,
    },
  },
  {
    path: 'ad0119',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8205,
    },
  },
  {
    path: 'ad0120',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8206,
    },
  },
  {
    path: 'ad0121',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8207,
    },
  },
  {
    path: 'ad0122',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8208,
    },
  },
  {
    path: 'ad0123',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8209,
    },
  },
  {
    path: 'ad0124',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8210,
    },
  },
  {
    path: 'ad0125',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8211,
    },
  },
  {
    path: 'ad0126',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8212,
    },
  },
  {
    path: 'ad0127',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8213,
    },
  },
  {
    path: 'ad0128',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8214,
    },
  },
  {
    path: 'ad0129',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8215,
    },
  },
  {
    path: 'ad0130',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8216,
    },
  },
  {
    path: 'ad0131',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8217,
    },
  },
  {
    path: 'ad0132',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8218,
    },
  },
  {
    path: 'ad0133',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8219,
    },
  },
  {
    path: 'ad0134',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8220,
    },
  },
  {
    path: 'ad0135',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8221,
    },
  },
  {
    path: 'ad0136',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8222,
    },
  },
  {
    path: 'ad0137',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8223,
    },
  },
  {
    path: 'ad0138',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8224,
    },
  },
  {
    path: 'ad0139',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8225,
    },
  },
  {
    path: 'ad0140',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8226,
    },
  },
  {
    path: 'ad0141',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8227,
    },
  },
  {
    path: 'ad0142',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8228,
    },
  },
  {
    path: 'ad0143',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8229,
    },
  },
  {
    path: 'ad0144',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8230,
    },
  },
  {
    path: 'ad0145',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8231,
    },
  },
  {
    path: 'ad0146',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8232,
    },
  },
  {
    path: 'ad0147',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8233,
    },
  },
  {
    path: 'ad0148',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8234,
    },
  },
  {
    path: 'ad0149',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8235,
    },
  },
  {
    path: 'ad0150',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 8235,
    },
  },
  {
    path: 'simulacao',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 3103,
    },
  },
  {
    path: 'meta-ig/fbq-15d-sem-cartao-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7019,
    },
  },
  {
    path: 'ad0001',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7761,
    },
  },
  {
    path: 'ad0002',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7762,
    },
  },
  {
    path: 'ad0003',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7763,
    },
  },
  {
    path: 'ad0004',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7764,
    },
  },
  {
    path: 'ad0005',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7765,
    },
  },
  {
    path: 'ad0006',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7767,
    },
  },
  {
    path: 'ad0007',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7768,
    },
  },
  {
    path: 'ad0008',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7779,
    },
  },
  {
    path: 'ad0009',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7780,
    },
  },
  {
    path: 'ad0010',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7781,
    },
  },
  {
    path: 'ad0011',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7782,
    },
  },
  {
    path: 'ad0012',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7783,
    },
  },
  {
    path: 'ad0013',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7784,
    },
  },
  {
    path: 'ad0014',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7785,
    },
  },
  {
    path: 'ad0015',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7786,
    },
  },
  {
    path: 'ad0016',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7787,
    },
  },
  {
    path: 'ad0017',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7788,
    },
  },
  {
    path: 'ad0018',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7789,
    },
  },
  {
    path: 'ad0019',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7790,
    },
  },
  {
    path: 'ad0020',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7791,
    },
  },
  {
    path: 'ad0021',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7872,
    },
  },
  {
    path: 'ad0022',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7873,
    },
  },
  {
    path: 'ad0023',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7793,
    },
  },
  {
    path: 'ad0024',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7794,
    },
  },
  {
    path: 'ad0025',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7795,
    },
  },
  {
    path: 'ad0026',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7796,
    },
  },
  {
    path: 'ad0027',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7797,
    },
  },
  {
    path: 'ad0028',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7798,
    },
  },
  {
    path: 'ad0029',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7799,
    },
  },
  {
    path: 'ad0030',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7800,
    },
  },
  {
    path: 'ad0031',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7801,
    },
  },
  {
    path: 'ad0032',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7802,
    },
  },
  {
    path: 'ad0033',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7803,
    },
  },
  {
    path: 'ad0034',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7804,
    },
  },
  {
    path: 'ad0035',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7805,
    },
  },
  {
    path: 'ad0036',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7806,
    },
  },
  {
    path: 'ad0037',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7807,
    },
  },
  {
    path: 'ad0038',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7808,
    },
  },
  {
    path: 'ad0039',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7809,
    },
  },
  {
    path: 'ad0040',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7810,
    },
  },
  {
    path: 'ad0041',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7811,
    },
  },
  {
    path: 'ad0042',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7812,
    },
  },
  {
    path: 'ad0043',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7813,
    },
  },
  {
    path: 'ad0044',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7814,
    },
  },
  {
    path: 'ad0045',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7815,
    },
  },
  {
    path: 'ad0046',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7816,
    },
  },
  {
    path: 'ad0047',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7817,
    },
  },
  {
    path: 'ad0048',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7818,
    },
  },
  {
    path: 'ad0049',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7819,
    },
  },
  {
    path: 'ad0050',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7820,
    },
  },
  {
    path: 'ad0051',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7821,
    },
  },
  {
    path: 'ad0052',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7822,
    },
  },
  {
    path: 'ad0053',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7823,
    },
  },
  {
    path: 'ad0054',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7824,
    },
  },
  {
    path: 'ad0055',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7825,
    },
  },
  {
    path: 'ad0056',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7826,
    },
  },
  {
    path: 'ad0057',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7827,
    },
  },
  {
    path: 'ad0058',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7829,
    },
  },
  {
    path: 'ad0059',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7830,
    },
  },
  {
    path: 'ad0060',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7831,
    },
  },
  {
    path: 'ad0061',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7832,
    },
  },
  {
    path: 'ad0062',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7833,
    },
  },
  {
    path: 'ad0063',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7834,
    },
  },
  {
    path: 'ad0064',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7835,
    },
  },
  {
    path: 'ad0065',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7836,
    },
  },
  {
    path: 'ad0066',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7837,
    },
  },
  {
    path: 'ad0067',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7838,
    },
  },
  {
    path: 'ad0068',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7839,
    },
  },
  {
    path: 'ad0069',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7840,
    },
  },
  {
    path: 'ad0070',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7841,
    },
  },
  {
    path: 'ad0071',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7842,
    },
  },
  {
    path: 'ad0072',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7843,
    },
  },
  {
    path: 'ad0073',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7844,
    },
  },
  {
    path: 'ad0074',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7845,
    },
  },
  {
    path: 'ad0075',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7846,
    },
  },
  {
    path: 'ad0076',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7847,
    },
  },
  {
    path: 'ad0077',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7848,
    },
  },
  {
    path: 'ad0078',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7849,
    },
  },
  {
    path: 'ad0079',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7850,
    },
  },
  {
    path: 'ad0080',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7851,
    },
  },
  {
    path: 'ad0081',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7852,
    },
  },
  {
    path: 'ad0082',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7853,
    },
  },
  {
    path: 'ad0083',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7854,
    },
  },
  {
    path: 'ad0084',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7855,
    },
  },
  {
    path: 'ad0085',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7856,
    },
  },
  {
    path: 'ad0086',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7857,
    },
  },
  {
    path: 'ad0087',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7858,
    },
  },
  {
    path: 'ad0088',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7859,
    },
  },
  {
    path: 'ad0089',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7860,
    },
  },
  {
    path: 'ad0090',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7861,
    },
  },
  {
    path: 'ad0091',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7862,
    },
  },
  {
    path: 'ad0092',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7863,
    },
  },
  {
    path: 'ad0093',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7864,
    },
  },
  {
    path: 'ad0094',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7865,
    },
  },
  {
    path: 'ad0095',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7866,
    },
  },
  {
    path: 'ad0096',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7867,
    },
  },
  {
    path: 'ad0097',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7868,
    },
  },
  {
    path: 'ad0098',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7869,
    },
  },
  {
    path: 'ad0099',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7870,
    },
  },
  {
    path: 'ad0100',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7871,
    },
  },
  {
    path: 'discovery',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7888,
    },
  },
  {
    path: 'pesquisa',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7887,
    },
  },
  {
    path: 'youtube',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7889,
    },
  },
  {
    path: "cotacao-redirect",
    loadChildren: () => import('./newlp-redirect/newlp-redirect.module').then(m => m.NewLpRedirectModule),
    data: {
      social: 8272
    }
  },
  {
    path: "cotacao-whats",
    loadChildren: () => import('./newlp-whats/newlp-whats.module').then(m => m.NewLpWhatsModule),
    data: {
      social: 8271
    }
  },
  {
    path: "cotacao-obrigado",
    loadChildren: () => import('./newlp-obrigado/newlp-obg.module').then(m => m.NewLpObrigaodModule),
    data: {
      social: 8270
    }
  },
  {
    path: 'meta-ig/fbq-15d-acidentes-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7020,
    },
  },
  {
    path: 'meta-ig/fbq-15d-motion-3-reais-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7021,
    },
  },
  {
    path: 'bio-ig',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7191,
    },
  },
  {
    path: 'meta-ig/fbq-15d-carro-3-reais-ao-dia-que-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7022,
    },
  },
  {
    path: 'meta-ig/fbq-15d-marido-não-confia-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7023,
    },
  },
  {
    path: 'meta-ig/fbq-15d-investimento-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7024,
    },
  },
  {
    path: 'meta-ig/fbq-15d-casos-felizes-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7025,
    },
  },
  {
    path: 'meta-page-url-obg-carro-3-reais-ao-dia-lp2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7414,
    },
  },
  {
    path: 'meta-page-url-obg-com-loma-sem-loma-lp2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7415,
    },
  },
  {
    path: 'meta-page-url-obg-quebra-de-objeção-lp2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7416,
    },
  },
  {
    path: 'meta-page-url-obg-investimento-lp2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7417,
    },
  },
  {
    path: 'meta-ig/fbq-15d-com-loma-sem-loma-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7026,
    },
  },
  {
    path: 'meta-ig/fbq-7d-sem-cartao-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7027,
    },
  },
  {
    path: 'meta-ig/fbq-7d-acidentes-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7028,
    },
  },
  {
    path: 'meta-ig/fbq-7d-motion-3-reais-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7029,
    },
  },
  {
    path: 'meta-ig/fbq-7d-carro-3-reais-ao-dia-Q-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7030,
    },
  },
  {
    path: 'meta-ig/fbq-7d-marido-não-confia-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7031,
    },
  },
  {
    path: 'meta-ig/fbq-7d-investimento-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7032,
    },
  },
  {
    path: 'meta-ig/fbq-7d-casos-felizes-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7033,
    },
  },
  {
    path: 'meta-ig/fbq-15d-quebradeobjeção-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7393,
    },
  },
  {
    path: 'meta-ig/fbq-7d-quebradeobjeção-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7394,
    },
  },
  {
    path: 'meta-pq15d-quebradeobjeção-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7396,
    },
  },
  {
    path: 'meta-pq7d-quebradeobjeção-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7397,
    },
  },
  {
    path: 'meta-pq3d-quebradeobjeção-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7398,
    },
  },
  {
    path: 'meta-pgv-20d-quebradeobjeção-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7399,
    },
  },
  {
    path: 'meta-pgv-30d-quebradeobjeção-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7400,
    },
  },
  {
    path: 'meta-pgv-45d-quebradeobjeção-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7401,
    },
  },
  {
    path: 'meta-llk-ativos-quebradeobjeção-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7402,
    },
  },
  {
    path: 'meta-llk-leads-quebradeobjeção-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7403,
    },
  },
  {
    path: 'meta-llk-ig/gb-30d-quebradeobjeção-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7404,
    },
  },
  {
    path: 'meta-p-350d-quebradeobjeção-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7405,
    },
  },
  {
    path: 'meta-llk-350d-quebradeobjeção-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7406,
    },
  },
  {
    path: 'meta-pa-sp-quebradeobjeção-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7407,
    },
  },
  {
    path: 'meta-is-portaisdecarros-quebradeobjeção-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7408,
    },
  },
  {
    path: 'meta-is-seguradoras-quebradeobjeção-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7409,
    },
  },
  {
    path: 'meta-is-carrosusados-quebradeobjeção-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7410,
    },
  },
  {
    path: 'meta-is-homens-quebradeobjeção-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7411,
    },
  },
  {
    path: 'meta-is-mulheres-quebradeobjeção-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7412,
    },
  },
  {
    path: 'meta-ig/fbq-7d-com-loma-sem-loma-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7034,
    },
  },
  {
    path: 'meta-pq15d-sem-cartao-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7035,
    },
  },
  {
    path: 'meta-pq15d-acidentes-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7036,
    },
  },
  {
    path: 'meta-pq15d-motion-3-reais-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7037,
    },
  },
  {
    path: 'meta-pq15d-carro-3-reais-ao-dia-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7038,
    },
  },
  {
    path: 'meta-pq15d-marido-não-confia-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7039,
    },
  },
  {
    path: 'meta-pq15d-investimento-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7040,
    },
  },
  {
    path: 'meta-pq15d-casos-felizes-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7041,
    },
  },
  {
    path: 'meta-pq15d-com-loma-sem-loma-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7042,
    },
  },
  {
    path: 'meta-pq7d-sem-cartao-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7043,
    },
  },
  {
    path: 'meta-pq7d-acidentes-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7044,
    },
  },
  {
    path: 'meta-pq7d-motion-3-reais-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7045,
    },
  },
  {
    path: 'meta-pq7d-carro-3-reais-ao-dia-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7046,
    },
  },
  {
    path: 'meta-pq7d-marido-não-confia-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7047,
    },
  },
  {
    path: 'meta-pq7d-investimento-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7048,
    },
  },
  {
    path: 'meta-pq7d-casos-felizes-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7049,
    },
  },
  {
    path: 'meta-pq7d-com-loma-sem-loma-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7050,
    },
  },
  {
    path: 'meta-pq3d-sem-cartao-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7051,
    },
  },
  {
    path: 'meta-pq3d-acidentes-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7052,
    },
  },
  {
    path: 'meta-pq3d-motion-3-reais-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7053,
    },
  },
  {
    path: 'meta-pq3d-carro-3-reais-ao-dia-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7054,
    },
  },
  {
    path: 'meta-pq3d-marido-não-confia-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7055,
    },
  },
  {
    path: 'meta-pq3d-investimento-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7056,
    },
  },
  {
    path: 'meta-pq3d-casos-felizes-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7057,
    },
  },
  {
    path: 'meta-pq3d-com-loma-sem-loma-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7058,
    },
  },
  {
    path: 'meta-pgv-20d-sem-cartao-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7059,
    },
  },
  {
    path: 'meta-pgv-20d-acidentes-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7060,
    },
  },
  {
    path: 'meta-pgv-20d-motion-3-reais-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7061,
    },
  },
  {
    path: 'meta-pgv-20d-carro-3-reais-ao-dia-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7062,
    },
  },
  {
    path: 'meta-pgv-20d-marido-não-confia-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7063,
    },
  },
  {
    path: 'meta-pgv-20d-investimento-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7064,
    },
  },
  {
    path: 'meta-pgv-20d-casos-felizes-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7065,
    },
  },
  {
    path: 'meta-pgv-20d-com-loma-sem-loma-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7066,
    },
  },
  {
    path: 'meta-pgv-30d-sem-cartao-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7067,
    },
  },
  {
    path: 'meta-pgv-30d-acidentes-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7068,
    },
  },
  {
    path: 'meta-pgv-30d-motion-3-reais-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7069,
    },
  },
  {
    path: 'meta-pgv-30d-carro-3-reais-ao-dia-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7070,
    },
  },
  {
    path: 'meta-pgv-30d-marido-não-confia-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7071,
    },
  },
  {
    path: 'meta-pgv-30d-investimento-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7072,
    },
  },
  {
    path: 'meta-pgv-30d-casos-felizes-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7073,
    },
  },
  {
    path: 'meta-pgv-30d-com-loma-sem-loma-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7074,
    },
  },
  {
    path: 'meta-pgv-45d-sem-cartao-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7075,
    },
  },
  {
    path: 'meta-pgv-45d-acidentes-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7076,
    },
  },
  {
    path: 'meta-pgv-45d-motion-3-reais-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7077,
    },
  },
  {
    path: 'meta-pgv-45d-carro-3-reais-ao-dia-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7078,
    },
  },
  {
    path: 'meta-pgv-45d-marido-não-confia-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7079,
    },
  },
  {
    path: 'meta-pgv-45d-investimento-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7080,
    },
  },
  {
    path: 'meta-pgv-45d-casos-felizes-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7081,
    },
  },
  {
    path: 'meta-pgv-45d-com-loma-sem-loma-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7082,
    },
  },
  {
    path: 'meta-llk-ativos-sem-cartao-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7083,
    },
  },
  {
    path: 'meta-llk-ativos-acidentes-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7084,
    },
  },
  {
    path: 'meta-llk-ativos-motion-3-reais-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7085,
    },
  },
  {
    path: 'meta-llk-ativos-carro-3-reais-ao-dia-T-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7088,
    },
  },
  {
    path: 'meta-llk-ativos-marido-não-confia-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7086,
    },
  },
  {
    path: 'meta-llk-ativos-investimento-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7090,
    },
  },
  {
    path: 'meta-llk-ativos-casos-felizes-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7091,
    },
  },
  {
    path: 'meta-llk-ativos-com-loma-sem-loma-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7092,
    },
  },
  {
    path: 'meta-llk-leads-sem-cartao-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7093,
    },
  },
  {
    path: 'meta-llk-leads-acidentes-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7094,
    },
  },
  {
    path: 'meta-llk-leads-motion-3-reais-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7095,
    },
  },
  {
    path: 'meta-llk-leads-carro-3-reais-ao-dia-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7096,
    },
  },
  {
    path: 'meta-llk-leads-marido-não-confia-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7097,
    },
  },
  {
    path: 'meta-llk-leads-investimento-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7098,
    },
  },
  {
    path: 'meta-llk-leads-casos-felizes-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7099,
    },
  },
  {
    path: 'meta-llk-leads-com-loma-sem-loma-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7100,
    },
  },
  {
    path: 'meta-llk-ig/gb-30d-sem-cartao-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7101,
    },
  },
  {
    path: 'meta-llk-ig/gb-30d-acidentes-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7102,
    },
  },
  {
    path: 'meta-llk-ig/gb-30d-motion-3-reais-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7103,
    },
  },
  {
    path: 'meta-llkig/gb-30d-carro-3-reais-ao-dia-T-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7104,
    },
  },
  {
    path: 'meta-llk-ig/gb-30d-marido-não-confia-T-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7105,
    },
  },
  {
    path: 'meta-llk-ig/gb-30d-investimento-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7106,
    },
  },
  {
    path: 'meta-llk-ig/gb-30d-casos-felizes-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7107,
    },
  },
  {
    path: 'meta-llk-ig/gb-30d-com-loma-sem-loma-T-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7108,
    },
  },
  {
    path: 'meta-p-350d-sem-cartao-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7109,
    },
  },
  {
    path: 'meta-p-350d-acidentes-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7110,
    },
  },
  {
    path: 'meta-p-350d-motion-3-reais-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7111,
    },
  },
  {
    path: 'meta-p-350d-carro-3-reais-ao-dia-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7112,
    },
  },
  {
    path: 'meta-p-350d-marido-não-confia-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7113,
    },
  },
  {
    path: 'meta-p-350d-investimento-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7114,
    },
  },
  {
    path: 'meta-p-350d-casos-felizes-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7115,
    },
  },
  {
    path: 'meta-p-350d-com-loma-sem-loma-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7116,
    },
  },
  {
    path: 'meta-llk-350d-sem-cartao-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7117,
    },
  },
  {
    path: 'meta-llk-350d-acidentes-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7118,
    },
  },
  {
    path: 'meta-llk-350d-motion-3-reais-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7119,
    },
  },
  {
    path: 'meta-llk-350d-carro-3-reais-ao-dia-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7120,
    },
  },
  {
    path: 'meta-llk-350d-marido-não-confia-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7121,
    },
  },
  {
    path: 'meta-llk-350d-investimento-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7122,
    },
  },
  {
    path: 'meta-llk-350d-casos-felizes-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7123,
    },
  },
  {
    path: 'meta-llk-350d-com-loma-sem-loma-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7124,
    },
  },
  {
    path: 'meta-pa-sp-sem-cartao-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7125,
    },
  },
  {
    path: 'meta-pa-sp-acidentes-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7126,
    },
  },
  {
    path: 'meta-pa-sp-motion-3-reais-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7127,
    },
  },
  {
    path: 'meta-pa-sp-carro-3-reais-ao-dia-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7128,
    },
  },
  {
    path: 'meta-pa-sp-marido-não-confia-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7129,
    },
  },
  {
    path: 'meta-pa-sp-investimento-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7130,
    },
  },
  {
    path: 'meta-pa-sp-casos-felizes-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7131,
    },
  },
  {
    path: 'meta-pa-sp-com-loma-sem-loma-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7132,
    },
  },
  {
    path: 'meta-is-portaisdecarros-sem-cartao-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7133,
    },
  },
  {
    path: 'meta-is-portaisdecarros-acidentes-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7134,
    },
  },
  {
    path: 'meta-is-portaisdecarros-motion-3-reais-T-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7135,
    },
  },
  {
    path: 'metaisportaidecar-carro-3-reais-ao-dia-T-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7136,
    },
  },
  {
    path: 'meta-is-portaisdecar-marido-não-confia-T-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7137,
    },
  },
  {
    path: 'meta-is-portaisdecarros-investimento-T-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7138,
    },
  },
  {
    path: 'meta-is-portaisdecarros-casos-felizes-T-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7139,
    },
  },
  {
    path: 'meta-is-portaisdecar-com-loma-sem-loma-T-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7140,
    },
  },
  {
    path: 'meta-is-seguradoras-sem-cartao-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7141,
    },
  },
  {
    path: 'meta-is-seguradoras-acidentes-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7142,
    },
  },
  {
    path: 'meta-is-seguradoras-motion-3-reais-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7143,
    },
  },
  {
    path: 'meta-is-segurador-carro-3-reais-ao-dia-T-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7144,
    },
  },
  {
    path: 'meta-is-seguradoras-marido-não-confia-T-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7145,
    },
  },
  {
    path: 'meta-is-seguradoras-investimento-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7146,
    },
  },
  {
    path: 'meta-is-seguradoras-casos-felizes-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7147,
    },
  },
  {
    path: 'meta-is-seguradoras-com-loma-sem-loma-T-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7148,
    },
  },
  {
    path: 'meta-is-carrosusados-sem-cartao-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7149,
    },
  },
  {
    path: 'meta-is-carrosusados-acidentes-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7150,
    },
  },
  {
    path: 'meta-is-carrosusados-motion-3-reais-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7151,
    },
  },
  {
    path: 'meta-is-carusados-carro-3-reais-ao-dia-T-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7152,
    },
  },
  {
    path: 'meta-is-carrosusados-investimento-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7153,
    },
  },
  {
    path: 'meta-is-carrosusados-casos-felizes-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7154,
    },
  },
  {
    path: 'meta-is-carrosusados-com-loma-sem-loma-T-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7155,
    },
  },
  {
    path: 'meta-is-homens-sem-cartao-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7156,
    },
  },
  {
    path: 'meta-is-homens-acidentes-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7157,
    },
  },
  {
    path: 'meta-is-homens-motion-3-reais-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7158,
    },
  },
  {
    path: 'meta-is-homens-carro-3-reais-ao-dia-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7159,
    },
  },
  {
    path: 'meta-is-homens-investimento-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7160,
    },
  },
  {
    path: 'meta-is-homens-casos-felizes-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7161,
    },
  },
  {
    path: 'meta-is-homens-com-loma-sem-loma-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7162,
    },
  },
  {
    path: 'meta-is-mulheres-sem-cartao-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7163,
    },
  },
  {
    path: 'meta-is-mulheres-acidentes-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7164,
    },
  },
  {
    path: 'meta-is-mulheres-motion-3-reais-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7165,
    },
  },
  {
    path: 'meta-is-mulheres-carro-3-reais-ao-dia-T-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7166,
    },
  },
  {
    path: 'meta-is-mulheres-marido-não-confia-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7167,
    },
  },
  {
    path: 'meta-is-mulheres-investimento-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7168,
    },
  },
  {
    path: 'meta-is-mulheres-casos-felizes-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7169,
    },
  },
  {
    path: 'meta-is-mulheres-com-loma-sem-loma-topo-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7170,
    },
  },
  {
    path: 'meta-vw-75-sem-cartao-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 6835,
    },
  },
  {
    path: 'meta-vw-75-acidentes-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 6836,
    },
  },
  {
    path: 'meta-vw-75-motion-3-reais-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 6837,
    },
  },
  {
    path: 'meta-vw-75-carro-3-reais-ao-dia-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 6838,
    },
  },
  {
    path: 'meta-vw-75-marido-não-confia-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 6839,
    },
  },
  {
    path: 'meta-pixc-sem-cartao-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 6849,
    },
  },
  {
    path: 'meta-pixc-acidentes-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 6850,
    },
  },
  {
    path: 'meta-pixc-motion-3-reais-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 6851,
    },
  },
  {
    path: 'meta-pixc-carro-3-reais-ao-dia-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 6852,
    },
  },
  {
    path: 'meta-pixc-marido-não-confia-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 6853,
    },
  },
  {
    path: 'meta-pixc-investimento-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 6854,
    },
  },
  {
    path: 'meta-pixc-com-loma-sem-loma-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 6855,
    },
  },
  {
    path: 'meta-pixc-casos-felizes-quente-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 6859,
    },
  },
  {
    path: 'meta-vw-75-casos-felizes-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 6861,
    },
  },
  {
    path: 'meta-vw-75-investimento-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 6840,
    },
  },
  {
    path: 'meta-vw-75-com-loma-sem-loma-meio-Lp-2',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 6841,
    },
  },
  {
    path: 'googleads',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 6672,
    },
  },
  {
    path: 'facebook',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 162,
    },
  },
  {
    path: 'google-display',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 3815,
    },
  },
  {
    path: 'google-pesquisa',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 5930,
    },
  },
  {
    path: 'googlepesquisa',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7524,
    },
  },
  {
    path: 'googledisplay',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7525,
    },
  },
  {
    path: 'googlevideo',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 7526,
    },
  },
  {
    path: 'instagram',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 163,
    },
  },
  {
    path: 'youtube',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 4946,
    },
  },
  {
    path: 'tiktok',
    loadChildren: () => import('./newlp/newlp.module').then(m => m.NewLpModule),
    data: {
      social: 5931,
    },
  },
  {
    path: 'pague-10-ganhe-2',
    loadChildren: () => import("./promo/promo.module").then(m => m.PromoModule)
  },
  {
    path: 'indique-e-ganhe-12',
    loadChildren: () => import('./promo-indique-e-ganhe-12/promo.module').then(m => m.Promo12Module)
  },
  {
    path: 'indique-e-ganhe-11-12',
    loadChildren: () => import("./promo-indique-e-ganhe-1211/promo.module").then(m => m.Promo1112Module),

  },
   {
    path: 'valide-seu-voucher-na-11-e-ganhe-12',
     loadChildren: () => import('./promo-indique-e-ganhe-12/promo.module').then(m => m.Promo12Module),
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
