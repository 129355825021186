import { Component, OnInit, NgZone } from '@angular/core';
import isMobile from 'ismobilejs';
import * as DeviceDetector from 'device-detector-js';

declare var window: any
declare var getIPs: any
declare var $: any

function ajaxAsAPromise(data: any) {
  return new Promise((resolve, reject) => {
    $.ajax({
      ...data,
      success: (res) => resolve(res),
      error: (e) => reject(e)
    })
  })
}





@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})



export class TrackingComponent implements OnInit {
  constructor(private ngZone: NgZone) {}
  ngOnInit(): void {
    this.ngZone.runOutsideAngular(() => {
      if(window.executed == undefined || window.executed == false) {
        window.executed = true;

        async function getDeviceType(data: any) {
          const deviceDetectorFunction = isMobile(navigator.userAgent);
          const deviceDetectorObj = new DeviceDetector();
          const device = deviceDetectorObj.parse(navigator.userAgent);
          const deviceType = deviceDetectorFunction.phone ? "celular" : deviceDetectorFunction.tablet ? "tablet" : "computador";

          $.ajax({
            method: "POST",
            crossDomain: true,
            url: "https://script.google.com/macros/s/AKfycbx86unT32tziD7ip_c9VJKrqJLdRaGitivvxTjnCItejKq23CsbdyIUNKyHuecwSxQQ/exec",
            data: {
              URL: window.location.href,
              ...data,
              OS_Nome: device.os.name,
              OS_Versao: device.os.version,
              Tipo_Dispositivo: deviceType,
              Navegador_Versao: device.client.version,
              Navegador: device.client.name
            }
          })


        }

        async function getIp() {


          const [ ip ] = await getIPs()


          try {
            const data:any = await ajaxAsAPromise({
              "async": true,
              "crossDomain": true,
              "url": "https://ipapi.co/json/",
              "method": "GET"
            })
            getDeviceType({ IP: data.ip, Pais: data.country_name, Regiao: data.region, Cidade: data.city })


          } catch(e) {
            getDeviceType({ IP: ip })
          }






        }

        getIp()
      }
    })
  }
}
